//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: #80090D;
	padding-bottom: 2.4rem;
	padding-top: calc(2.4rem + 49px);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: $transition-base;
	overflow: hidden;
	color: white;

	@media (max-width:991px) {
		padding-bottom: .8rem;
		padding-top: calc(.8rem + 39px);

		&.sticky {
			padding-bottom: .8rem !important;
			padding-top: calc(.8rem + 39px) !important;
		}
	}

	@media (max-width:576px) {
		padding-top: calc(.8rem + 29px);

		&.sticky {
			padding-bottom: .8rem !important;
			padding-top: .8rem !important;
		}
	}

	a {
		color: inherit;
	}

	img.pattern {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		object-fit: cover;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	&.sticky {
		padding: 1rem 0;
		#header-extras {
			top: -65px;
		}
		
		#logo {
			max-width: 250px;
		}
	}

	#logo {
		width: 100%;
		max-width: 360px;
		transition: $transition-base;
		a {
			display: inline-block;
		}

		@media (max-width:991px) {
			max-width: 130px !important;
		}
	}

	#header-extras {
		position: fixed;
		top: 0;
		left: 0;
		transition: all .3s linear;
		margin-top: 0;
		width: 100vw;
		background-color: white;
		color: $color-primary;
		padding: .5rem .9rem;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		z-index: 1;

		&.closed,
		&.fully-closed {
			background-color: $color-black;
			color: white;
		}

		.extra {
			display: inline-block;
			margin-right: 25px;
			&:last-child {
				margin-right: 0;
			}
		}

		@media (max-width:991px) {
			padding: 0.3rem 0.5rem;
		}

		@media (max-width:576px) {
			justify-content: center;
			font-size: 0.42rem !important;

			p {
				font-size: inherit !important;
			}
		}
	}

	#menu {
		transition: all 0.4s linear;
		margin-top: 60px;
		float: right;
		#menu-main-container {
			display: inline-block;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;
				li {
					float: left;
					position: relative;
					margin-right: 20px;
					a {
						font-size: 18px;
						display: block;
						color: $color-primary;
						&:hover {
							color: $color-link-hover;
						}
					}
					&:last-child {
						margin-right: 0;
					}
					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: #ffffff;
						}
						.sub-menu {
							li {
								a {
									color: $color-primary;
								}
							}
						}
					}
					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -30px;
					    transform: scale(0.95) translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							a {
								display: block;
								color: $color-primary;
								background-color: $color-gray-darker;
								padding: 20px 35px;
								&:hover {
									color: $color-link-hover;
									background-color: lighten($color-gray-darker, 3%);
								}
							}
							&:last-child {
								margin-bottom: 0;
							}
							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: #ffffff;
								}
							}
						}
					}
					&.menu-item-has-children {
						&:after {
							font-family: "Font Awesome 5 Pro";
							content: '\f105';
							display: block;
							position: absolute;
							top: 50%;
							right: -12px;
							font-weight: 100;
							transform: translateY(-50%);
							transform-origin: center;
							color: white;
							transition: transform 0.25s;
						}
						&:hover {
							&:after {
								transform: translateY(-50%) rotate(90deg);
							}
							.sub-menu {
								height: auto;
								opacity: 1;
							    transform: scale(1) translateY(0);
								padding-top: 27px;
							}
						}
					}
				}
			}
		}
	}
}
