//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: white;
	color: $color-primary;
	font-size: 0.6rem;
	padding: $gigantic 0;
	padding-bottom: 2.5rem;

	@media (max-width:576px) {
		font-size: .45rem;
		padding: 1rem 0;
		padding-bottom: .8rem;
	}

	a {
		color: inherit;

		&.file {
			margin-left: .7rem;
		}
	}

	p {
		font-size: inherit !important;
		margin: 0;
	}

	#footer-top {
		display: flex;
    align-items: center;
    justify-content: space-between;

		.logo {
			width: 100%;
			max-width: 7.2rem;

			img {
				width: 100%;
			}

			@media (max-width:576px) {
				max-width: 2.8rem;
			}
		}

		.socials {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: .8rem;
			margin: 0;
			padding: 0;

			a {
				display: inline-block;

				svg {
					width: 100%;
					max-width: 1.1rem;
					height: auto;

					@media (max-width:576px) {
						max-width: 0.8rem;
					}
				}
			}
		}
	}

	#footer-bottom {
		margin-top: 1rem;
		padding: 1rem 0;
		padding-bottom: 0;
		border-top: 1px solid $color-primary;
		text-transform: uppercase;
		display: flex;
		align-content: center;
		justify-content: space-between;
		gap: 1rem;

		.signature {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: .3rem;
		}

		@media (max-width:576px) {
			flex-wrap: wrap;
			align-items: flex-start;
			gap: .5rem;
			padding-top: .3rem;
			margin-top: .8rem;
		}
	}
}
