//
// Template Styles
// --------------------------------------------------

body {
    &.error404 {

        #main {
            min-height: calc(100vh - 237px);

            @media (max-width:576px) {
                min-height: calc(100vh - 113px);
            }
        }
    }
}
#main {
    padding-top: 225px; // Header height
    z-index: 15;

    @media (max-width:767px) {
        padding-top: 113px;
    }

    @media (max-width:991px) {
        padding-top: 104px;
    }

    @media (max-width:576px) {
        padding-top: 103px;
    }
}


#page-header {
    width: 100vw;
    // max-width: 1920px;
    // margin: 0 auto;
    
    
    &.home-header {
        height: calc(90vh - 225px);
        overflow: hidden;
        
        h1 {
            @media (max-width: 1441px) and (min-width: 1200px) {
                font-size: 2.7rem !important;
                line-height: 2.7rem;
            }
        }

        .header-content {
            display: grid;
            grid-template-columns: 66.5% 33.5%;
            height: 100%;

            .imgs-slider {
                margin: 0 !important;
                height: 100%;
                overflow: hidden;

                .slick-list,
                .slick-track {
                    height: 100%;
                }

                .slick-dots {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 45px;

                    li {
                        margin: 0 5px !important;

                        @media (max-width:991px) {
                            margin: 0 2px !important;
                        }

                        button {
                            width: 12px !important;
                            height: 12px !important;

                            @media (max-width:991px) {
                                width: 4px !important;
                                height: 4px !important;
                            }
                        }
                    }
                }
            }

            .img-container {
                display: block;
                height: 100%;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    // position: absolute;
                    // left: 50%;
                    // top: 0;
                    // transform: translateX(-50%);
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                padding: $huge;
                padding-right: 0;
                padding-bottom: 1rem;
                position: relative;

                p {
                    width: 100%;
                    max-width: calc(100% - 75px);
                    margin-bottom: 0;
                }

                svg {
                    position: absolute;
                    right: 10px;
                    bottom: 1rem;
                    width: 38px;
                    height: auto;
                    transition: all .2s linear;
                }
            }

            a.content {
                &:hover {
                    
                    svg {
                        right: -0.3rem;
                    }
                }
            }

            @media (max-width:1920px) {
                padding: 0;
                max-width: 1920px !important;

                .content {
                    padding-right: $gigantic;

                    svg {
                        right: $gigantic;
                    }
                }

                a.content {
                    &:hover {

                        svg {
                            right: 2.5rem;
                        }
                    }
                }
            }

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);

                .imgs-slider {
                    width: 50vw;
                }
            }
        }

        @media (max-width:767px) {
            height: fit-content;

            .header-content {
                grid-template-columns: 1fr;

                .imgs-slider {
                    height: calc(75vh - 104px);
                    width: 100vw;
                }

                .content {
                    padding: 0.9rem 20px;

                    svg {
                        width: .7rem;
                        right: 32px;
                    }
                }
            }
        }
    }

    &.standard-header {
        max-width: 2060px;
        margin: 0 auto;
        margin-bottom: 2rem;
        padding: 0 70px;

        &.no-img,
        &.not-found-header {

            .content {
                margin-top: 2rem;
            }
        }

        .img-container {
            width: 100%;
            height: calc(90vh - 225px);
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .header-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 3.6rem;
        }

        .content {
            padding: 0;
        }

        @media (max-width:1920px) {
            padding: 0;
            max-width: 1920px!important;

            .content {
                padding: 0 45px;
            }
        }

        @media (max-width:991px) {
            margin-bottom: 1rem;

            .img-container {
                height: calc(75vh - 104px);
            }

            .header-content {
                gap: 1.2rem;
            }
        }

        @media (max-width:767px) { 

            .content {
                padding: 0 20px;
            }
        }
    }
}

#page-not-found {
    margin-top: 4rem;
    margin-bottom: 2rem;

    .error-actions {

        .button {
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: .7rem;
            width: fit-content;
            white-space: nowrap;

            svg#arrow {
                height: 1rem;
                width: auto;
                transform: rotateY(180deg);

                rect {
                    fill: white;
                }
            }
        }
    }
}

#page-default {

    .post-content {

        h2 {
            margin-top: $large;

            &:first-child {
                margin-top: 0;
            }
        }

        h3 {
            margin-top: .8rem;
        }

        .wp-block-columns {
            margin-bottom: 3rem;

            @media (max-width:782px) {
                margin-bottom: 2rem;
            }
        }
    }
}

#page-homepage {

    section {

        &:nth-child(5n + 1){

            .accordion {

                .title {
                    background-color: $color-orange;
                }
            }
        }

        &:nth-child(5n + 3){

            .accordion {

                .title {
                    background-color: $color-beige;
                }
            }
        }

        &:nth-child(5n + 4){

            .accordion {

                .title {
                    background-color: $color-brown;
                }
            }
        }

        &:nth-child(5n){

            .accordion {

                .title {
                    background-color: $color-burgundy-darker;
                }
            }
        }

        &.marin {

            .accordion {

                .title {

                    img, svg.plus-title {
                        width: auto;
                        height: auto;
                        max-height: 41px;

                        @media (max-width:991px) {
                            max-height: 25px;
                        }

                        @media (max-width:767px) {
                            max-height: 18px;
                        }
                    }
                }
            }
        }

        &.pro {

            .accordion {

                .left,
                .right {

                    p {
                        margin-top: 1.4rem;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .accordion {

            .schedules {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 4rem;
                text-transform: uppercase;

                @media (max-width:1199px) {
                    grid-template-columns: 1fr;
                    gap: 1.8rem;
                }

                .regular-sched {
                    
                    ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        
                        li {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 1rem;
                            margin-bottom: .5rem;

                            @media (max-width:1199px) {
                                grid-template-columns: 4fr 2fr;
                                gap: 4rem;
                            }

                            @media (max-width:991px) {
                                gap: 2rem;
                            }

                            @media (max-width:767px) {
                                grid-template-columns: repeat(2, 1fr);
                            }

                            @media (max-width:576px) {
                                gap: .5rem;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .specials {

                    p {
                        padding-bottom: $massive;
                        border-bottom: 2px solid $color-primary;
                        margin-bottom: $massive;

                        @media (max-width:991px) {
                            padding-bottom: .5rem;
                        }

                        @media (max-width:576px) {
                            font-size: 14px !important;
                        }
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;

                        li {
                            display: grid;
                            grid-template-columns: 1fr 3fr 2fr;
                            gap: 2rem;
                            margin-bottom: .3rem;

                            @media (max-width:991px) {
                                gap: 1rem;
                            }

                            @media (max-width:576px) {
                                gap: .5rem;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .stores-content {

                .map {
                    width: 100%;
                    max-width: 90%;
                    margin: 0 auto;
                    margin-bottom: 4.5rem;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 2rem;
                    height: auto;

                    .map-content {
                        grid-column: span 2;
                        max-width: 32rem;

                        img {
                            width: 100%;
                            height: auto;
                        }

                        svg {
                            #FLOOR_0,
                            #FLOOR_1,
                            #FLOOR_2 {
                                transform: translateY(0);
                                transition: all 0.5s ease-in-out;
                                &.active {
                                    transform: translateY(-380px)
                                }
                                &.active-last {
                                    #_2_SANS_SHOP {
                                        transition: all 0.5s ease-in-out;
                                        opacity: 0;
                                        pointer-events: none;
                                    }
                                }
                                g {
                                    &[id$="_HOVER"] {
                                        cursor: pointer;
                                        transition: all 0.1s ease-in-out;
                                        opacity: 0;
                                        &:hover {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .floors-content {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        ul {
                            padding: 0;
                            margin: 0;
                            list-style: none;

                            li {
                                margin-bottom: 1rem;
                                opacity: .3;
                                transition: all .2s linear;

                                &.is-active,
                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }

                        sup {
                            font-size: .7rem;
                        }
                    }

                    @media (max-width:1441px) {
                        grid-template-columns: repeat(2, 1fr);
                        gap: 4rem;

                        .map-content {
                            grid-column: span 1;
                        }
                    }

                    @media (max-width:991px) {
                        grid-template-columns: 1fr;

                        .map-content {
                            order: 2;
                        }

                        .floors-content {
                            order: 1;
                            width: 100%;

                            ul {
                                width: 100%;
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
                                gap: 1rem;
                            }
                        }
                    }

                    @media (max-width:767px) {
                        margin-top: 1rem;
                        gap: 1rem;

                        .floors-content {

                            sup {
                                font-size: .5rem;
                            }

                            ul {
                                font-size: $small !important;
                                line-height: normal !important;
                            }
                        }
                    }
                }
            }

            .food-stores,
            .stores-content {
                text-transform: uppercase;

                .slider-content {
                    position: relative;

                    .slick-prev,
                    .slick-next {
                        @media (max-width:991px) {
                            display: none;
                        }
                    }
                }

                .stores-slider {
                    width: 100%;
                    max-width: 90%;
                    margin: 0 auto;
                    margin-bottom: 1.5rem;

                    @media (max-width:991px) {
                        box-shadow: 0 3px 6px rgba(#000000, .15);
                        padding: $massive;
                        padding-bottom: 1.7rem;
                        margin-bottom: 0;
                    }
                    
                    @media (max-width:767px) {
                        max-width: 100%;
                    }
                }

                .store {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 2rem;
                    height: auto;

                    .infos {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: flex-start;

                        .logo, 
                        h3 {
                            margin: 0 auto;
                            margin-bottom: auto;
                        }

                        .logo {
                            max-width: 10.8rem;
                            width: auto;
                            max-height: 4rem;
                        }
                    }

                    .img-container {
                        grid-column: span 2;
                        max-width: 920px;
                        margin-left: auto;

                        img {
                            width: 100%;
                        }
                    }

                    @media (max-width:1441px) {
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1.5rem;

                        .img-container {
                            grid-column: span 1;
                        }

                        .infos {

                            .logo,
                            h3 {
                                margin-bottom: $massive;
                            }
                        }
                    }

                    @media (max-width:991px) {
                        display: block;

                        .img-container {
                            display: none;
                            visibility: hidden;
                        }

                        .infos {

                            .logo,
                            h3 {
                                margin: 0 auto;
                                margin-bottom: $massive;
                            }

                            .contact {
                                margin-top: $big;
                            }
                        }
                    }

                    @media (max-width:767px) {

                        .infos {

                            .logo {
                                max-width: 5.5rem;
                            }
                        }
                    }
                }

                .store-schedules {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    width: 100%;
                    
                    li {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 1rem;
                        margin-bottom: 0;
                    }
                }

                .contact {
                    margin-top: 3rem;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;
                    width: 100%;
                }
            }

            .access {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 2rem;
                row-gap: 5rem;
                text-transform: uppercase;

                svg {
                    display: block;
                    margin-bottom: 1rem;
                }

                .car,
                .transport,
                .parking,
                .stations {
                    width: fit-content;
                    max-width: calc(25% - 2rem);
                }

                .address,
                .maps {
                    width: calc(50% - 2rem);
                    display: flex;
                    align-items: center;
                }

                .maps {
                    justify-content: flex-end;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 1rem;

                        &:hover {
                            
                            svg {
                                margin-right: -0.3rem;
                            }
                        }

                        svg {
                            width: 2.2rem;
                            height: auto;
                            display: initial;
                            margin: 0;
                            transition: all .2s linear;
                        }
                    }
                }

                @media (max-width:991px) {
                    row-gap: $huge;

                    .car,
                    .transport,
                    .parking,
                    .stations {
                        width: calc(50% - 2rem);
                        max-width: calc(50% - 2rem);
                    }

                    .maps {
                        justify-content: flex-start;
                    }
                }

                @media (max-width:767px) {

                    .address,
                    .maps {
                        width: fit-content;
                    }

                    .maps {

                        a {

                            svg {
                                width: 1rem;
                            }
                        }
                    }
                }

                @media (max-width:576px) {

                    .car,
                    .transport,
                    .parking,
                    .stations {
                        font-size: .68rem !important;
                    }
                }
            }
        }
    }
}