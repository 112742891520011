//
// Font Faces Styles
// @help: url('../webfonts/xxx)
// --------------------------------------------------

//ABC Favorit
@font-face {
  font-family: 'ABC Favorit Expanded';
  src: url('../webfonts/abc-favorit/ABCFavoritExpanded-Medium.woff2') format('woff2'),
      url('../webfonts/abc-favorit/ABCFavoritExpanded-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Favorit Expanded';
  src: url('../webfonts/abc-favorit/ABCFavoritExpanded-Regular.woff2') format('woff2'),
      url('../webfonts/abc-favorit/ABCFavoritExpanded-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Favorit';
  src: url('../webfonts/abc-favorit/ABCFavorit-Regular.woff2') format('woff2'),
      url('../webfonts/abc-favorit/ABCFavorit-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


//Font Awesome
@font-face {
  font-family: "Font Awesome 6 Brands";
  src: url("../webfonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  src: url("../webfonts/fontawesome/fa-sharp-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-sharp-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}
