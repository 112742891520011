

// Base

html,
body {
  overflow-x: hidden !important;
}

p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.container {
  width: 100%;
  max-width: 2060px !important;
  margin: 0 auto;
  padding: 0 70px;
  
  @media (max-width:1920px) {
    padding: 0 45px;
    max-width: 1780px !important;
  }

  @media (max-width:767px) {
    padding: 0 20px;
  }
}

.small:not(input, select, textarea) {
  font-size: $small; //16px
  line-height: normal;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size:$regular-mobile !important;
    line-height: normal;
  }
}

.regular,
p {
  font-size: $regular; //25px
  line-height: normal;
  font-family: $font-base;
  font-weight: normal;
  margin: 0;

  @media (max-width: 991px) {
    font-size: $small !important;
    line-height: normal;
  }
}

p {
  margin: $regular 0;

  &:first-child {
    margin-top: 0;
  }
}

.medium,
h6 {
  font-size: $medium !important; //20px
  line-height: normal;
  font-family: $font-base;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: $regular !important;
    line-height: normal;
  }
}

.big,
h5 {
  font-size: $big !important; //22px
  line-height: normal;
  font-family: $font-base;
  font-weight: normal;

  @media (max-width:991px) {
    font-size: $medium !important;
    line-height: normal;
  }
}

.large:not(input, select, textarea),
h4 {
  font-size: $large !important;
  line-height: normal;
  font-family: $font-base;
  font-weight: normal;

  @media (max-width:991px) {
    font-size: $big !important;
    line-height: normal;
  }
}

.massive,
h3 {
  font-size: $massive !important;
  line-height: normal;
  font-family: $font-base;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size: $large !important;
    line-height: normal;
  }
}

.huge,
h2 {
  font-size: $huge !important;
  line-height: normal;
  font-family: $font-title;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size: $massive !important;
    line-height: normal;
  }
}

.gigantic,
h1 {
  font-size: $gigantic !important;
  line-height: $gigantic;
  font-family: $font-title;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: $huge !important;
    line-height: $huge;
  }
}
